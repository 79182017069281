import _withGoogleAnalyticsListener from './hocs/withGoogleAnalyticsListener'
import _withRUMListener from './hocs/withRUMListener'
import { sendRUMEvent as _sendRUMEvent } from "./utilities/rum"

export const withRUMListener = _withRUMListener;
export const withGoogleAnalyticsListener = _withGoogleAnalyticsListener;
export const sendRUMEvent = _sendRUMEvent

export default {
  withGoogleAnalyticsListener,
  withRUMListener,
  sendRUMEvent
};
